.meta-info, .article-body {
  margin: 5px 0;
}

.article {
  margin-bottom: 3rem;
}

.articles .buttons-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.articles .buttons-wrapper button:not(:first-child) {
  margin-left: 0.5rem;
}

.tweet-box {
  padding: 1rem 0.5rem;
  border: 1px solid #ddd;
  margin-top: 1rem;
  line-height: 1.5;
  position: relative;

  &:before {
    content: attr(data-before);
    position: absolute;
    top: -1rem;
    background: #eee;
    padding: 3px 5px;
    right: 0;
    border-radius: 1rem;
    font-size: 90%;
  }
}

.featured-image {
  width: 300px;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .featured-image {
    width: 300px;
  }
}