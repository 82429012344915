:root {
  --active-link: #F6716F;
  --max-body-width: 600px;
}

.App {
  text-align: left;
  margin: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.articles p {
  font-size: 12px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--active-link);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main {
  display: flex;
}

.body {
  max-width: var(--max-body-width);
  margin: 90px auto 6rem;
}

.sidebar {
  max-width: 400px;
  margin: 100px 10px;
  line-height: 1.5;
  font-style: italic;
  font-weight: 300;
  position: sticky;
  align-self: start;
  top: 100px;
}

.sidebar h3 {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.header {
  background-color: var(--active-link);
  padding: 1rem 1rem 0;
  color: white;
  font-weight: bold;
  font-family: inherit;
  font-size: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.header span {
  font-size: 60%;
  margin-left: 0.3rem;
}

.footer {
  position: fixed;
  bottom: 0;
  padding: 1rem;
  left: 0;
  right: 0;
  background-image: linear-gradient(to right, #eee, #888);
  font-size: 12px;
}

.articles h3 {
  margin-bottom: 0;
}

.articles a {
  color: #444444;
}

.meta-info {
  color: #888888;
}

a, a:hover {
  color: var(--active-link);
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .body {
    margin-top: 120px;
  }

  .hidden-xs {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .hidden-not-xs {
    display: none !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
