.subscription-form {
  position: relative;
  top: -10px;
  padding: 0;

  .title {
    top: 10px;
    font-size: 11px;
    margin: 0;
  }

  input, button {
    font-size: 11px;
  }
}

@media only screen and (max-width: 600px) {
  .subscription-form {
    top: 0;
    margin: 1rem 0 0.5rem;
  }
}